body {
  font-family: Montserrat, sans-serif;
  background-image: linear-gradient(to right, #ffffff, #18212b80);
  padding-right: 0 !important;
}

.navbar-brand {
  background-image: url("./assets/images/sadeteamlogo.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 168px;
  height: 68px;
}

.navbar-nav .nav-item:hover {
  background-color: rgba(180, 190, 203, 0.4);
}